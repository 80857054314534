export const USER_ROLE = {
  ADMIN: 'ADMIN',
  BUYER_ADMIN: 'BUYER_ADMIN',
  BUYER_MANAGER: 'BUYER_MANAGER',
  BUYER_STAFF: 'BUYER_STAFF',
  SELLER_ADMIN: 'SELLER_ADMIN',
  SELLER_CENTRAL: 'SELLER_CENTRAL',
  SELLER_BRANCH: 'SELLER_BRANCH',
  CUSTOMER: 'CUSTOMER',
  ADMIN_PROCUREMENT: 'ADMIN_PROCUREMENT',
};

export default {};
