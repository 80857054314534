import { clearEmptyObject } from 'global/utils/object.utils';
import { API_PREFIX, apiRequest } from 'utils/request.utils';

export const initiateRoomApi = async ({
  storeId = 0,
  branchId = 0,
  buyerId = 0,
  customRoomName = '',
  customRoomAvatar = '',
}) => {
  const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).post(
    '/chat/business/room',
    {
      storeId,
      branchId,
      buyerId,
      customRoomName,
      customRoomAvatar,
    },
  );

  return response?.data?.data;
};

export const sendMessageToRoomApi = async ({ id_room, chat = '' }) => {
  const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).post(
    '/chat/business/message',
    {
      id_room,
      chat,
    },
  );
  return response?.data?.data;
};

export const fetchRoomsApi = async ({ page = 1, size = 20, search = null }) => {
  const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).get(
    '/chat/business/rooms',
    {
      params: clearEmptyObject({
        page,
        size,
        customRoomName: search,
      }),
    },
  );
  return response?.data?.data;
};

export const fetchRoomHistoryApi = async (id, { page, size }, signal = undefined) => {
  const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).get(
    '/chat/business/room/history',
    {
      signal,
      params: {
        id,
        page,
        size,
      },
    },
  );
  return response?.data?.data;
};

export const getUnreadCountRoomsApi = async () => {
  try {
    const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).get(
      '/chat/business/message-room/unread',
    );
    return response?.data?.data;
  } catch {
    return [];
  }
};

export const getDetailRoomApi = async ({ id, code }) => {
  const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).get(
    '/chat/business/room/metadata',
    {
      params: {
        id,
        code,
      },
    },
  );
  return response?.data?.data;
};

export const getLastMessageInRoomsApi = async () => {
  const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).get(
    '/chat/business/room/last-message',
  );
  return response?.data?.data;
};
