import { throttle } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

export const useNotificationSound = () => {
  const audio = useRef();

  useEffect(() => {
    audio.current = new Audio(
      'https://storage.googleapis.com/xooply-assets-public/sounds/notification-new-message-1.mp3',
    );
  }, []);

  const playSound = useCallback(
    throttle(() => {
      audio.current?.play();
    }, 3000),
    [],
  );

  return {
    playSound,
  };
};

export default {};
