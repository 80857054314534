/* eslint-disable linebreak-style */
import useCategoryStore from 'stores/category.store';
import Link from 'next/link';

const HeaderCategory = () => {
  const { categories } = useCategoryStore();

  return (
    <div className="hidden lg:flex gap-5">
      {categories?.[0]?.childs?.map((item) => (
        <Link href={item?.redirectUrl} key={item?.id}>
          <a className="text-base font-medium text-[#222222] tracking-[0.2px]">{item?.name}</a>
        </Link>
      ))}
    </div>
  );
};

export default HeaderCategory;
