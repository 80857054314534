import { ArrowLeftIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import { useRouter } from 'next/router';

const BackButton = ({ onClick, label = 'Kembali' }) => {
  const router = useRouter();
  const handleClick = () => {
    return onClick ? onClick() : router.back();
  };

  return (
    <button
      className="rounded-full px-3 py-2 flex items-center gap-2 bg-white shadow-md text-theme-primary-main border border-grayScale02"
      onClick={handleClick}
    >
      <div className="bg-theme-primary-main text-white rounded-full w-[20px] h-[20px] flex items-center justify-center">
        <ArrowLeftIcon width={12} height={10} color="white" />
      </div>
      <span className="text-xs">Kembali</span>
    </button>
  );
};

export default BackButton;
