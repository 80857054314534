import shallow from 'zustand/shallow';
import { ChatIcon } from '@heroicons/react/solid';
import { useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { CONFIG_SITE } from 'constants/index';
import { BadgeCounter } from '@utils/ui';
import { isEnableChatRetail } from 'global/utils/chat.utils';
import useChatStore from '../_store/useChatStore';
import { QUERY_KEYS, REFRESH_INTERVAL_UNREAD_COUNT } from '../_constants/Chat.constants';
import { getUnreadCountRoomsApi } from './_networks';
import ChatWrapper from './_components/ChatWrapper';
import { useNotificationSound } from '../_hooks';
import { useAuth } from 'utils/context/auth';
import { USER_ROLE } from 'global/constants/user';

const ChatRetailButton = () => {
  const { user } = useAuth();
  const { playSound } = useNotificationSound();
  const queryClient = useQueryClient();
  const { setShowChat, unreadRooms, setUnreadRooms, totalUnreadRooms, setTotalUnreadRooms } =
    useChatStore(
      (state) => ({
        setShowChat: state.setShowChat,
        unreadRooms: state.unreadRooms,
        setUnreadRooms: state.setUnreadRooms,
        totalUnreadRooms: state.totalUnreadRooms,
        setTotalUnreadRooms: state.setTotalUnreadRooms,
      }),
      shallow,
    );

  const convertUnreadRoomsArrayToObject = useCallback((data = []) => {
    const object = {};
    (data ?? []).forEach((item) => {
      object[item.roomId] = item.unreadCount;
    });
    return object;
  }, []);

  useQuery(QUERY_KEYS.unreadRooms, () => getUnreadCountRoomsApi(), {
    onSuccess: (responseData) => {
      const newUnreadRoomsObject = convertUnreadRoomsArrayToObject(responseData);
      setUnreadRooms(newUnreadRoomsObject);

      let count = 0;
      Object.keys(newUnreadRoomsObject).forEach((key) => {
        count += newUnreadRoomsObject[key] ?? 0;
      });
      if (count > totalUnreadRooms) {
        playSound();
      }
      if (count !== unreadRooms) {
        setTotalUnreadRooms(count);
      }
    },
    retry: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries(QUERY_KEYS.unreadRooms);
    }, REFRESH_INTERVAL_UNREAD_COUNT);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const isShow = useMemo(() => {
    if ([USER_ROLE.CUSTOMER].includes(user?.auth?.role ?? '')) {
      return isEnableChatRetail(CONFIG_SITE);
    }

    return false;
  }, [user?.auth?.role]);

  if (!isShow) {
    return null;
  }

  return (
    <>
      <button type="button" className="relative" onClick={() => setShowChat(true)}>
        <ChatIcon width={24} height={24} color="#4F4F4F" />
        {totalUnreadRooms > 0 && (
          <BadgeCounter
            count={totalUnreadRooms}
            style={{ position: 'absolute', top: -12, right: -10, height: 18, width: 18 }}
          />
        )}
      </button>
      <ChatWrapper />
    </>
  );
};

export default ChatRetailButton;
