import { useState, useEffect, useMemo } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { Menu } from '@headlessui/react';
import {
  InformationCircleIcon,
  LoginIcon,
  UserIcon,
  ArchiveIcon,
  LogoutIcon,
  OfficeBuildingIcon,
  HomeIcon,
  UsersIcon,
  GlobeAltIcon,
} from '@heroicons/react/solid';
import Link from 'next/link';
import { useAuth } from 'utils/context/auth';
import { CONFIG_SITE, USER_ROLE } from 'constants/index';
import { currencyFormatter } from 'global/utils/currency.utils';
import useGetHeaderHeight from 'hooks/useGetHeaderHeight';
import { BUYING_OPTIONS } from 'global/constants/buying-options';
import { isValidBuyingType } from 'global/utils/global-config.utils';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { XIcon } from '@heroicons/react/outline';
import { Divider } from 'antd';
import useCategoryStore from 'stores/category.store';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

const MobileMenu = ({ isHomepage, remainingBalance, isShowInfo, subMenu = [], isLayoutDisabled = false }) => {
  const router = useRouter();
  const { user, logout } = useAuth();
  const [isBuyerStaff, setIsBuyerStaff] = useState(false);
  const { isLogedIn } = user.auth;
  const isCallpro = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS?.callpro;
  const [showedMenu, setShowedMenu] = useState(false);
  const { categories } = useCategoryStore();
  // Temporary hide "Consumer Goods & Disposable", "Medical Equipment & Health", and "Voucher"
  const categoriesModified = categories?.filter((category) => category?.name !== 'Consumer Goods & Disposable' && category?.name !== 'Medical Equipment & Health' && category?.name !== 'Voucher');

  const visibilityBudgetInformation = useMemo(() => {
    if ([WHITELABEL_KEYS.idfood].includes(CONFIG_SITE?.WHITELABEL_KEY)) {
      return false;
    }

    return true;
  }, []);

  const parentMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Child Company',
      url: '/dashboard/parent/user-child',
      icon: ArchiveIcon,
    },
    {
      id: 3,
      title: 'Pesanan Saya',
      url: '/dashboard/parent/transaction-request',
      icon: ArchiveIcon,
    },
    {
      id: 4,
      title: 'Informasi',
      url: '/dashboard/parent/budget',
      icon: InformationCircleIcon,
      visible: visibilityBudgetInformation,
    },
  ];

  const managerMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Pesanan Saya',
      url: '/dashboard/manager/transaction-request',
      icon: ArchiveIcon,
    },
    // {
    //   id: 3,
    //   title: "Anggaran",
    //   url: "/dashboard/manager/budget",
    //   icon: CogIcon,
    // },
    // {
    //   id: 3,
    //   title: "Notifikasi Saya",
    //   url: "/dashboard/manager/notification",
    //   icon: BellIcon,
    // },
    {
      id: 4,
      title: 'Informasi',
      url: '/dashboard/manager/budget',
      icon: InformationCircleIcon,
      visible: visibilityBudgetInformation,
    },
  ];

  const staffMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Pesanan Saya',
      url: '/dashboard/staff/transaction-request',
      icon: ArchiveIcon,
    },
    // {
    //   id: 3,
    //   title: "Notifikasi Saya",
    //   url: "/dashboard/staff/notification",
    //   icon: BellIcon,
    // },
    {
      id: 4,
      title: 'Informasi',
      url: '/dashboard/staff/budget',
      icon: InformationCircleIcon,
      visible: visibilityBudgetInformation,
    },
  ];

  const unauthenticatedMenus = [
    {
      id: 1,
      title: 'Daftar',
      url: isValidBuyingType(CONFIG_SITE, BUYING_OPTIONS.rent)
        ? '/register/retail'
        : '/register/retail/buyer',
      visible: CONFIG_SITE?.IS_SHOW?.REGISTER_RETAIL ?? false,
      icon: UsersIcon,
    },
    {
      id: 2,
      title: 'Daftar',
      url: '/register/business',
      visible: CONFIG_SITE?.SELF_REGISTER_B2B ?? false,
      icon: UsersIcon,
    },
    {
      id: 3,
      title: 'Masuk',
      url: '/login',
      icon: LoginIcon,
    },
    {
      id: 4,
      title: 'Ruang Kantor',
      url: '/search?cateIds=9',
      visible: isCallpro,
      icon: OfficeBuildingIcon,
    },
    {
      id: 4,
      title: 'Lahan Kosong',
      url: '/search?cateIds=214',
      visible: isCallpro,
      icon: GlobeAltIcon,
    },
    {
      id: 5,
      title: 'Properti Lainnya',
      url: '/search?cateIds=215',
      visible: isCallpro,
      icon: HomeIcon,
    },
  ];

  const customerMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Pesanan Saya',
      url: '/dashboard/customer/transaction-list',
      icon: ArchiveIcon,
    },
    // {
    //   id: 3,
    //   title: "Notifikasi Saya",
    //   url: "/dashboard/staff/notification",
    //   icon: BellIcon,
    // },
    {
      id: 3,
      title: 'Ruang Kantor',
      url: '/search?cateIds=9',
      visible: isCallpro,
      icon: OfficeBuildingIcon,
    },
    {
      id: 4,
      title: 'Lahan Kosong',
      url: '/search?cateIds=214',
      visible: isCallpro,
      icon: GlobeAltIcon,
    },
    {
      id: 5,
      title: 'Properti Lainnya',
      url: '/search?cateIds=215',
      visible: isCallpro,
      icon: HomeIcon,
    },
  ];

  const menus = useMemo(() => {
    let menuUser;
    switch (user.auth.role) {
      case USER_ROLE.BUYER_ADMIN:
        menuUser = parentMenus;
        break;
      case USER_ROLE.BUYER_MANAGER:
        menuUser = managerMenus;
        break;
      case USER_ROLE.BUYER_STAFF:
        menuUser = staffMenus;
        setIsBuyerStaff(true);
        break;
      case USER_ROLE.CUSTOMER:
        menuUser = customerMenus;
        break;
      default:
        menuUser = [];
    }

    return menuUser;
  }, [user.auth.role]);

  const headerHeight = useGetHeaderHeight();

  const renderMenus = (dataMenus) => (
    <>
      {dataMenus?.map((menu) => (
        <Menu.Item key={menu.title}>
          {({ active }) => (
            <Link href={menu.path} className="tesss">
              <a
                className={clsx(
                  active ? 'bg-gray-100' : '',
                  menu?.visible ?? true
                    ? 'group relative flex flex-row items-center py-3 font-semibold text-base text-grayScale05 group-hover:text-theme-primary-main hover:text-theme-primary-main'
                    : 'hidden',
                )}
              >
                <div className="w-[20] h-[20]">
                  {menu.icon && (
                    <menu.icon
                      width={20}
                      height={20}
                      className="relative text-grayScale05 mr-3 group-hover:text-theme-primary-main"
                    />
                  )}
                </div>
                {menu.title}
              </a>
            </Link>
          )}
        </Menu.Item>
      ))}
    </>
  );

  useEffect(() => {
    setShowedMenu(false);
  }, [router.pathname]);

  return (
    <>
      <div className="flex flex-row items-center">
        <span
          role="button"
          tabIndex={0}
          className="relative rounded-lg p-3 py-1.5 bg-transparent text-white flex items-center justify-center w-[16px] h-[16px] cursor-pointer"
          onClick={() => {
            if (!isLayoutDisabled) {
              setShowedMenu(!showedMenu);
            }
          }}
        >
          <Image src="/assets/icons/icon-bars.svg" alt="icon-bars.svg" layout="fill" />
        </span>
      </div>
      <div
        className="fixed z-50 w-full h-full bg-white left-0 right-0 top-0 transition-all ease-in-out"
        style={{
          transform: showedMenu ? 'translateX(0)' : 'translateX(100%)',
          // transition: 'translate 0.3s ease-in-out',
        }}
      >
        <div className="flex p-4 justify-between">
          <div className="w-[120px] h-auto">
            <a href="/" className="">
              <img src={CONFIG_SITE?.LOGO_URL} alt="Logo" className="h-[32px] w-auto" />
            </a>
          </div>
          <div
            role="button"
            tabIndex={0}
            className="flex items-center"
            onClick={() => setShowedMenu(false)}
          >
            <XIcon width={26} height={26} className="text-grayScale05 cursor-pointer" />
          </div>
        </div>
        <Divider className="mt-0 mb-2" />
        <div className="flex flex-col gap-2 overflow-y-scroll overflow-x-hidden h-full py-2 px-4 text-sm pb-[160px]">
          <div>
            <h3 className="font-semibold">Menu</h3>
            <ul className="flex flex-col gap-2 py-2">
              {isLogedIn ? (
                <>
                  {!isBuyerStaff && !visibilityBudgetInformation && (
                    <li className="relative">
                      <div className="flex">
                        <div className="flex w-10 h-10 relative">
                          <Image
                            src="/assets/icons/icon-budget-red.svg"
                            alt="Icon Budget"
                            width="25px"
                            height="25px"
                            layout="fill"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="mb-0.5 text-grayScale04 text-xs font-normal">
                            Sisa Anggaran
                          </p>
                          <p className="text-theme-primary-main text-base font-semibold">
                            Rp. {currencyFormatter(remainingBalance)}
                          </p>
                        </div>
                        <div className="hide lg:block absolute top-0 -right-4 flex w-[220px] h-[100px] ">
                          <Image
                            src="/assets/illustrations/accent-bg-red.svg"
                            alt="accent red"
                            width="50px"
                            height="500px"
                            layout="fill"
                          />
                        </div>
                      </div>
                    </li>
                  )}
                  {menus.map((menuItem) => {
                    const active = false;
                    return (
                      <>
                        {(menuItem?.visible ?? true) && (
                          <li>
                            <Link href={menuItem.url} className="tesss">
                              <a
                                className={clsx(
                                  active ? 'bg-gray-100' : '',
                                  'group relative flex flex-row items-center font-semibold text-base text-grayScale05 group-hover:text-theme-primary-main hover:text-theme-primary-main',
                                )}
                              >
                                <div className="w-[20] h-[20]">
                                  {menuItem.icon && (
                                    <menuItem.icon
                                      width={20}
                                      height={20}
                                      className="relative text-grayScale05 mr-3 group-hover:text-theme-primary-main"
                                    />
                                  )}
                                </div>
                                {menuItem.title}
                              </a>
                            </Link>
                          </li>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {unauthenticatedMenus.map((menuItem) => {
                    const active = false;
                    if (menuItem.visible ?? true) {
                      return (
                        <li>
                          <Link href={menuItem.url} className="tesss">
                            <a
                              className={clsx(
                                active ? 'bg-gray-100' : '',
                                'group relative flex flex-row items-center font-semibold text-base text-grayScale05 group-hover:text-theme-primary-main hover:text-theme-primary-main',
                              )}
                            >
                              <div className="w-[20] h-[20]">
                                {menuItem.icon && (
                                  <menuItem.icon
                                    width={20}
                                    height={20}
                                    className="relative text-grayScale05 mr-3 group-hover:text-theme-primary-main"
                                  />
                                )}
                              </div>
                              {menuItem.title}
                            </a>
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                </>
              )}
            </ul>
          </div>
          {!isCallpro && (
            <div>
              <h3 className="font-semibold">Kategori</h3>
              <ul className="flex flex-col gap-2 py-2">
                {(categoriesModified || []).map((category, index) => (
                  <li key={index}>
                    <Link href={category.redirectUrl || '/'}>
                      <a>{category.name}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div>
            <Divider />
            <ul className="flex flex-col gap-2 py-2">
              {subMenu.map((item, index) => (
                <li key={index}>
                  <Link href={item.path}>
                    <a>{item.title}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isLogedIn && (
          <div className="absolute bottom-0 left-0 right-0 bg-white">
            <Divider className="m-0" />
            <div className="py-2 px-4">
              <span
                role="button"
                tabIndex={0}
                onClick={() => {
                  logout();
                }}
                className="group flex flex-row items-center py-3 font-semibold text-base text-grayScale05 group-hover:text-theme-primary-main hover:text-theme-primary-main cursor-pointer"
              >
                <LogoutIcon
                  width={20}
                  height={20}
                  className="relative text-grayScale05 mr-3 group-hover:text-theme-primary-main hover:text-theme-primary-main"
                />
                Log out
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );

  // return (
  //   <Menu as="div" className="relative">
  //     {({ open }) => (
  //       <>
  //         <div className="flex flex-row items-center">
  //           <>
  //             <Menu.Button className="flex flex-row items-center mx-2">
  //               <span className="relative rounded-lg p-3 py-1.5 bg-transparent text-white flex items-center justify-center w-[16px] h-[16px]">
  //                 <Image src="/assets/icons/icon-bars.svg" alt="icon-bars.svg" layout="fill" />
  //               </span>
  //             </Menu.Button>
  //             {open && (
  //               <Transition
  //                 as={Fragment}
  //                 enter="transition ease-out duration-200"
  //                 enterFrom="opacity-0 translate-y-1"
  //                 enterTo="opacity-100 translate-y-0"
  //                 leave="transition ease-in duration-150"
  //                 leaveFrom="opacity-100 translate-y-0"
  //                 leaveTo="opacity-0 translate-y-1"
  //               >
  //                 <div
  //                   className={clsx('fixed left-0 right-0 bottom-0 z-20 bg-black/[0.3]')}
  //                   style={{ top: headerHeight }}
  //                   onPointerDown={(e) => e.stopPropagation()}
  //                 ></div>
  //               </Transition>
  //             )}
  //           </>
  //         </div>
  //         <Transition
  //           as={Fragment}
  //           enter="transition ease-out duration-200"
  //           enterFrom="transform opacity-0 scale-95"
  //           enterTo="transform opacity-100 scale-100"
  //           leave="transition ease-in duration-75"
  //           leaveFrom="transform opacity-100 scale-100"
  //           leaveTo="transform opacity-0 scale-95"
  //         >
  //           <Menu.Items className="origin-top-right absolute right-0 mt-2 w-[300px] rounded-[15px] shadow-lg px-[30px] py-7 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
  //             {isLogedIn ? (
  //               <>
  //                 {isBuyerStaff && visibilityBudgetInformation && (
  //                   <Menu.Item className="relative px-4 py-5 mb-5 space-x-4 items-center flex flex-row drop-shadow-md bg-white rounded-lg overflow-hidden">
  //                     <div className="flex">
  //                       <div className="flex w-10 h-10 relative">
  //                         <Image
  //                           src="/assets/icons/icon-budget-red.svg"
  //                           alt="Icon Budget"
  //                           width="25px"
  //                           height="25px"
  //                           layout="fill"
  //                         />
  //                       </div>
  //                       <div className="flex flex-col">
  //                         <p className="mb-0.5 text-grayScale04 text-xs font-normal">
  //                           Sisa Anggaran
  //                         </p>
  //                         <p className="text-theme-primary-main text-base font-semibold">
  //                           Rp. {currencyFormatter(remainingBalance)}
  //                         </p>
  //                       </div>
  //                       <div className="absolute top-0 -right-4 flex w-[220px] h-[100px] ">
  //                         <Image
  //                           src="/assets/illustrations/accent-bg-red.svg"
  //                           alt="accent red"
  //                           width="50px"
  //                           height="500px"
  //                           layout="fill"
  //                         />
  //                       </div>
  //                     </div>
  //                   </Menu.Item>
  //                 )}
  //                 {renderMenus(menus)}
  //                 <Menu.Item>
  //                   <p
  //                     onClick={() => {
  //                       logout();
  //                     }}
  //                     className="group flex flex-row items-center py-3 font-semibold text-base text-grayScale05 group-hover:text-theme-primary-main hover:text-theme-primary-main cursor-pointer"
  //                   >
  //                     <LogoutIcon
  //                       width={20}
  //                       height={20}
  //                       className="relative text-grayScale05 mr-3 group-hover:text-theme-primary-main hover:text-theme-primary-main"
  //                     />
  //                     Log out
  //                   </p>
  //                 </Menu.Item>
  //               </>
  //             ) : (
  //               renderMenus(unauthenticatedMenus)
  //             )}
  //           </Menu.Items>
  //         </Transition>
  //       </>
  //     )}
  //   </Menu>
  // );
};

MobileMenu.propTypes = {
  isHomepage: PropTypes.any,
  remainingBalance: PropTypes.any,
  isShowInfo: PropTypes.any,
  subMenu: PropTypes.any,
};

export default MobileMenu;
