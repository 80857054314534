import create from 'zustand';

const initialState = {
  showChat: false,
  rooms: [],
  unreadRooms: {},
  totalUnreadRooms: 0,
  lastMessageInRooms: {},
  activeRoomId: null,
  activeRoomData: null,
  filterRooms: {
    page: 1,
    size: 20,
    search: null,
  },
  filterActiveRoom: {
    page: 1,
    size: 20,
  },
  roomHistory: [],
  initialComposeMessage: null,
};

const useChatStore = create((set, get) => ({
  ...initialState,
  setShowChat: (isShow = false) => set({ showChat: isShow }),
  setActiveRoomId: (activeRoomId) => set({ activeRoomId }),
  setActiveRoomData: (activeRoomData) => set({ activeRoomData }),
  setRooms: (rooms = []) => set({ rooms }),
  setUnreadRooms: (unreadRooms = {}) => set({ unreadRooms }),
  setTotalUnreadRooms: (totalUnreadRooms = 0) => set({ totalUnreadRooms }),
  setLastMessageInRooms: (lastMessageInRooms = {}) => set({ lastMessageInRooms }),
  setFilterRooms: (newFilter) => set({ filterRooms: { ...get().filterRooms, ...newFilter } }),
  setFilterActiveRoom: (newFilter) =>
    set({ filterActiveRoom: { ...get().filterRooms, ...newFilter } }),
  setInitialComposeMessage: (initialComposeMessage) => set({ initialComposeMessage }),
}));

export default useChatStore;
