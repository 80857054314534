export const isValidBuyingType = (
  configSite = {},
  targetBuyingOption,
  defaultNullishBuyingOptionValue = null
) => {
  if (defaultNullishBuyingOptionValue !== null) {
    return (configSite?.BUYING_TYPE ?? defaultNullishBuyingOptionValue) === targetBuyingOption;
  }

  return configSite?.BUYING_TYPE === targetBuyingOption;
};
