import { isNil, omitBy } from 'lodash';

export const clearEmptyObject = (object) => {
  return omitBy(object, isNil);
};

export const isJsonString = (str = '') => {
  let value = str;
  try {
    value = JSON.parse(str);

    return typeof value === 'object' && value !== null;
  } catch (e) {
    return false;
  }
};

export const convertJsonStringToObject = (str) => {
  return JSON.parse(str);
};

export const convertObjectToJsonString = (str) => {
  return JSON.parse(str);
};
