import { Drawer } from '@utils/ui';
import { isMobile } from 'react-device-detect';
import { LeftOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';
import { useCallback, useEffect, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useTransition, animated } from '@react-spring/web';
import ActiveRoom from './ActiveRoom';
import useChatStore from '../../_store/useChatStore';
import Rooms from './Rooms';
import { PAGE_SIZE_ROOMS, QUERY_KEYS } from '../../_constants/Chat.constants';
import { fetchRoomsApi } from '../_networks';

const ChatWrapper = () => {
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    search: null,
  });

  const { activeRoomId, setActiveRoomId, setRooms, setShowChat, showChat } = useChatStore(
    (state) => ({
      activeRoomId: state.activeRoomId,
      setActiveRoomId: state.setActiveRoomId,
      setRooms: state.setRooms,
      activeRoomData: state.activeRoomData,
      setShowChat: state.setShowChat,
      showChat: state.showChat,
    }),
    shallow,
  );

  const { fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: QUERY_KEYS.listRoomsWithParams(filter),
      queryFn: ({ pageParam: page = 1 }) =>
        fetchRoomsApi({ page, size: PAGE_SIZE_ROOMS, ...filter }),
      getNextPageParam: (lastPage, allPages = []) => {
        if (lastPage?.content?.length >= PAGE_SIZE_ROOMS) {
          return allPages.length + 1;
        }
        return undefined;
      },
      onSuccess: (data) => {
        const newRooms = (data.pages || []).map((item) => item.content || []).flat();
        setRooms(newRooms);
      },
      retry: 0,
    });

  const handleBack = useCallback(() => {
    if (!activeRoomId) {
      setShowChat(false);
    }

    return setActiveRoomId(null);
  }, [activeRoomId, setActiveRoomId, setShowChat]);

  const handleLoadMore = () => {
    if (hasNextPage && !isLoading && !isFetchingNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEYS.listRooms);
  }, [showChat, activeRoomId]);

  const transitionRooms = useTransition(!activeRoomId, {
    from: { x: -100, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    leave: { x: -100, opacity: 0 },
  });

  const transitionActiveRoom = useTransition(!!activeRoomId, {
    from: { x: 100, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    leave: { x: 100, opacity: 0 },
  });

  const handleSearchRoom = (value) => {
    // invalidate query of old filter
    queryClient.invalidateQueries(QUERY_KEYS.listRoomsWithParams(filter));

    // set new filter to make new api call
    const newFilter = { ...filter, search: value };
    setFilter(newFilter);
  };

  return (
    <Drawer
      open={showChat}
      placement="right"
      title="Chat"
      onClose={handleBack}
      width={isMobile ? '100%' : 420}
      closeIcon={<LeftOutlined />}
      bodyStyle={{ padding: 0, margin: 0, position: 'relative', overflowX: 'hidden' }}
    >
      {transitionRooms(
        (style, item) =>
          item && (
            <animated.div style={{ ...style }} className="absolute inset-0">
              <Rooms
                onLoadMore={handleLoadMore}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onSearch={handleSearchRoom}
              />
            </animated.div>
          ),
      )}
      {transitionActiveRoom(
        (style, item) =>
          item && (
            <animated.div style={{ ...style }} className="absolute inset-0">
              <ActiveRoom />
            </animated.div>
          ),
      )}
    </Drawer>
  );
};

export default ChatWrapper;
