import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { convertChatDateTimeToMoment } from './date-time.utils';

export const isEnableChatB2B = (CONFIG_SITE = {}) => {
  return CONFIG_SITE?.CHAT?.IS_ENABLE_B2B ?? false;
};

export const isEnableChatRetail = (CONFIG_SITE = {}) => {
  return CONFIG_SITE?.CHAT?.IS_ENABLE_RETAIL ?? false;
};

export const isEnableChat = (CONFIG_SITE = {}) => {
  return [
    // // DEBUG-NOTE
    // WHITELABEL_KEYS.warungMekaar, // temporarry use warung mekaar to develop b2c chat
    // WHITELABEL_KEYS.xooply,
    // //
    // WHITELABEL_KEYS.callpro,
    // only enable idfood in production
    WHITELABEL_KEYS.idfood,
  ].includes(CONFIG_SITE.WHITELABEL_KEY);
};

export const convertMessageToMessageItem = (message) => ({
  id: message.id,
  value: message.chat,
  isMe: !!message.isSenderBuyer,
  time: convertChatDateTimeToMoment(message.createdAt).format('HH:mm'),
  status: 'sent', // assign default status
});

export default {};
