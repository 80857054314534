export const PAGE_SIZE_ROOMS = 30;
export const PAGE_SIZE_HISTORY = 100;
export const REFRESH_INTERVAL_ACTIVE_ROOM = 5000;
export const REFRESH_INTERVAL_UNREAD_COUNT = 5000;

export const QUERY_KEYS = {
  listRooms: ['chat', 'list-room'],
  listRoomsWithParams: (params = {}) => ['chat', 'list-room', params],
  roomItem: (id) => ['chat', 'room-item', id],
  unreadRooms: ['chat', 'unread-rooms'],
  lastMessageInRooms: ['chat', 'last-message-in-rooms'],
};

export default {};
