import { Button as ButtonAntd, ButtonProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type ButtonProperties = ButtonProps & {
  variant?: 'default' | 'primary' | 'outlined' | 'text' | 'dashed';
  size?: SizeType;
};

const Button: React.FC<ButtonProperties> = ({
  variant = 'default',
  title,
  size = 'middle',
  className,
  ...restProps
}) => {
  return (
    <ButtonAntd
      title={title}
      size={size}
      {...restProps}
      className={`button variant-${variant} size-${size} ${className}`}
    />
  );
};

export default Button;
