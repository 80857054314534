import { Col, Divider, Input, Row } from 'antd';
import useChatStore from 'containers/Chat/_store/useChatStore';
import shallow from 'zustand/shallow';
import SimpleBar from 'simplebar-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { throttle } from 'lodash';
import { Loader } from '@utils/ui';
import { useQuery, useQueryClient } from 'react-query';
import {
  QUERY_KEYS,
  REFRESH_INTERVAL_UNREAD_COUNT,
} from 'containers/Chat/_constants/Chat.constants';
import RoomItemWrapper from './RoomItemWrapper';
import { getLastMessageInRoomsApi } from '../_networks';

import 'simplebar/dist/simplebar.min.css';

const Rooms = ({
  onLoadMore = () => {},
  hasNextPage = false,
  isFetchingNextPage = false,
  onSearch = () => {},
}) => {
  const queryClient = useQueryClient();
  const { rooms, setLastMessageInRooms } = useChatStore(
    (state) => ({
      rooms: state.rooms,
      setLastMessageInRooms: state.setLastMessageInRooms,
    }),
    shallow,
  );

  const handleLoadMore = useCallback(
    throttle(() => {
      onLoadMore();
    }, 1000),
    [],
  );

  const wrapperRef = useRef();

  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target;
    if (scrollTop + offsetHeight + 100 >= scrollHeight && hasNextPage) {
      handleLoadMore();
    }
  };

  const convertLastMessageInRoomsArrayToObject = useCallback((data = []) => {
    const object = {};
    (data ?? []).forEach((item) => {
      object[item.roomId] = item;
    });
    return object;
  }, []);

  useQuery(QUERY_KEYS.lastMessageInRooms, () => getLastMessageInRoomsApi(), {
    onSuccess: (responseData) => {
      const newLastMessageInRooms = convertLastMessageInRoomsArrayToObject(
        responseData?.content || [],
      );
      setLastMessageInRooms(newLastMessageInRooms);
    },
    retry: 0,
  });

  const handleReloadQueries = () => {
    queryClient.invalidateQueries(QUERY_KEYS.lastMessageInRooms);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleReloadQueries();
    }, REFRESH_INTERVAL_UNREAD_COUNT);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    handleReloadQueries();
  }, []);

  const renderContent = useMemo(
    () => (
      <>
        {rooms.length <= 0 && (
          <div className="flex flex-col gap-2 justify-center items-center">
            <div>Anda belum memiliki percakapan</div>
          </div>
        )}
        <Row>
          {rooms.map((item, index) => (
            <Col key={item.id} span={24}>
              {index > 0 && <Divider className="my-1" />}
              <RoomItemWrapper data={item} />
            </Col>
          ))}
        </Row>
        <div className="flex justify-center pt-4">
          {isFetchingNextPage && (
            <div className="flex gap-3 items-center justify-center text-gray-500 w-full bg-gray-100 rounded-xl py-2 px-3">
              <Loader size={20} />
              <span className="text-xs">Sedang memuat...</span>
            </div>
          )}
        </div>
      </>
    ),
    [isFetchingNextPage, rooms],
  );

  return (
    <div className="max-w-screen-sm mx-auto h-full pt-[60px] relative">
      <div className="absolute top-0 left-0 right-0 p-3">
        <Input.Search placeholder="Cari" onSearch={onSearch} allowClear />
      </div>
      <SimpleBar className="p-3 h-full pr-4" ref={wrapperRef} onScrollCapture={handleScroll}>
        {renderContent}
      </SimpleBar>
    </div>
  );
};

export default Rooms;
