/* eslint-disable no-shadow */
import { Chat } from '@utils/ui';
import { useMutation, useQueryClient } from 'react-query';
import useChatStore from 'containers/Chat/_store/useChatStore';
import shallow from 'zustand/shallow';
import { useCallback, useMemo } from 'react';
import { isJsonString } from 'global/utils/object.utils';
import { QUERY_KEYS } from 'containers/Chat/_constants/Chat.constants';
import { convertChatDateTimeToMoment } from 'global/utils/date-time.utils';
import toast from 'react-hot-toast';
import { getDetailRoomApi } from '../_networks';

import 'moment/locale/id';

const RoomItemWrapper = ({ data }) => {
  const queryClient = useQueryClient();
  const { customRoomAvatar, customRoomName, id, sellerCentralStore, sellerBranchStore, code } =
    data;

  const { activeRoomId, setActiveRoomId, setActiveRoomData, unreadRooms, lastMessageInRooms } =
    useChatStore(
      (state) => ({
        activeRoomId: state.activeRoomId,
        setActiveRoomId: state.setActiveRoomId,
        setActiveRoomData: state.setActiveRoomData,
        unreadRooms: state.unreadRooms,
        lastMessageInRooms: state.lastMessageInRooms,
      }),
      shallow,
    );

  const sellerInformation = useMemo(
    () => ({
      name: sellerBranchStore
        ? sellerBranchStore?.branchCompanyName
        : sellerCentralStore?.companyName,
    }),
    [sellerBranchStore, sellerCentralStore?.companyName],
  );

  const roomProperties = useMemo(() => {
    let lastMessage = '';
    let lastMessageAt = '';
    // const { content = [] } = history || {};
    // if (content.length > 0) {
    //   const [firstMessage] = content;
    //   const { chat, createdAt } = firstMessage;
    //   lastMessage = isJsonString(chat) ? JSON.parse(chat).text || '' : chat;
    //   lastMessageAt = createdAt
    //     ? convertChatDateTimeToMoment(createdAt).format('DD MMMM YYYY HH:mm')
    //     : '';
    // }

    if (id in lastMessageInRooms) {
      const { chat, createdAt } = lastMessageInRooms[id];
      lastMessage = isJsonString(chat) ? JSON.parse(chat).text || '' : chat;
      lastMessageAt = createdAt
        ? convertChatDateTimeToMoment(createdAt).format('DD MMMM YYYY HH:mm')
        : '';
    }

    return {
      id,
      code,
      name: customRoomName ?? sellerInformation.name,
      avatar: customRoomAvatar ?? '',
      lastMessage,
      lastMessageAt,
      unreadCount: id in unreadRooms ? unreadRooms[id] : 0,
    };
  }, [
    code,
    customRoomAvatar,
    customRoomName,
    id,
    lastMessageInRooms,
    sellerInformation.name,
    unreadRooms,
  ]);

  const mutationGetDetailChat = useMutation(({ id, code }) =>
    getDetailRoomApi({
      id,
      code,
    }),
  );

  const handleSelectRoom = useCallback(async () => {
    try {
      const responseData = await mutationGetDetailChat.mutateAsync({ id, code });

      setActiveRoomId(responseData.id);
      setActiveRoomData(responseData);
      queryClient.invalidateQueries(QUERY_KEYS.unreadRooms);
    } catch (error) {
      console.error(error);
      toast.error('terjadi kesalahan');
    }
  }, [code, id, mutationGetDetailChat, queryClient, setActiveRoomData, setActiveRoomId]);

  return (
    <Chat.RoomItem
      {...roomProperties}
      onClick={handleSelectRoom}
      isActive={data.id === activeRoomId}
    />
  );
};

export default RoomItemWrapper;
