import { Chat } from '@utils/ui';
import { convertChatDateTimeToMoment } from 'global/utils/date-time.utils';
import React, { useCallback, useMemo } from 'react';
import { convertMessageToMessageItem } from 'global/utils/chat.utils';
import { USER_ROLE } from 'constants/index';
import { useAuth } from 'utils/context/auth';
import useChatStore from 'containers/Chat/_store/useChatStore';
import shallow from 'zustand/shallow';

const MessageItemWrapper = ({ message, activeRoomData }) => {
  const { setInitialComposeMessage } = useChatStore(
    (state) => ({
      setInitialComposeMessage: state.setInitialComposeMessage,
    }),
    shallow,
  );

  const { createdAt } = message;
  const { user } = useAuth();

  const { lastSeenSellerAt = null } = activeRoomData || {};

  const messageProperties = useMemo(() => {
    const objectData = convertMessageToMessageItem(message);

    if (createdAt) {
      const lastSeenSellerMoment = lastSeenSellerAt
        ? convertChatDateTimeToMoment(lastSeenSellerAt).unix()
        : 0;

      const createdAtMoment = convertChatDateTimeToMoment(createdAt).unix();

      if (lastSeenSellerMoment >= createdAtMoment) {
        objectData.status = 'read'; // update status to read when last seen more than message created at
      }
    }

    return objectData;
  }, [createdAt, lastSeenSellerAt, message]);

  const handleClickItemPreview = useCallback((type, meta) => {
    if (type === 'product_retail') {
      const { id } = meta || {};
      if (id) {
        window.open(`/product/${id}`, '_blank');
      }
    }

    if (type === 'po') {
      const { purchaseOrderId } = meta || {};

      let role = '';
      switch (user?.auth?.role) {
        case USER_ROLE.BUYER_ADMIN:
          role = 'parent';
          break;
        case USER_ROLE.BUYER_MANAGER:
          role = 'manager';
          break;
        case USER_ROLE.BUYER_STAFF:
          role = 'staff';
          break;

        default:
          break;
      }
      if (purchaseOrderId) {
        window.open(`/dashboard/${role}/transaction-list/${purchaseOrderId}`, '_blank');
      }
    }
  });

  const handleReply = (value) => {
    setInitialComposeMessage({
      type: 'reply',
      text: '',
      quote: value,
    });
  };

  return (
    <Chat.MessageItem
      onClickPreview={handleClickItemPreview}
      onReply={handleReply}
      {...messageProperties}
    />
  );
};

const lastSeenBySellerAreEqual = (prevProps, nextProps) =>
  prevProps.activeRoomData?.lastSeenSellerAt === nextProps.activeRoomData?.lastSeenSellerAt;

export default React.memo(MessageItemWrapper, lastSeenBySellerAreEqual);
