import { Chat, Loader } from '@utils/ui';
import useChatStore from 'containers/Chat/_store/useChatStore';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import shallow from 'zustand/shallow';
import {
  PAGE_SIZE_HISTORY,
  REFRESH_INTERVAL_ACTIVE_ROOM,
} from 'containers/Chat/_constants/Chat.constants';
import SimpleBar from 'simplebar-react';
import { throttle } from 'lodash';
import moment from 'moment';
import { fetchRoomHistoryApi, getDetailRoomApi, sendMessageToRoomApi } from '../_networks';
import MessageItemWrapper from './MessageItemWrapper';

import 'simplebar/dist/simplebar.min.css';

const ActiveRoom = () => {
  // SHARED STORE
  const {
    initialComposeMessage,
    activeRoomId,
    setInitialComposeMessage,
    activeRoomData,
    setActiveRoomId,
    setActiveRoomData,
  } = useChatStore(
    (state) => ({
      initialComposeMessage: state.initialComposeMessage,
      activeRoomId: state.activeRoomId,
      setInitialComposeMessage: state.setInitialComposeMessage,
      activeRoomData: state.activeRoomData,
      setActiveRoomId: state.setActiveRoomId,
      setActiveRoomData: state.setActiveRoomData,
    }),
    shallow,
  );

  const lastMessageDate = useRef(null);

  // DEFINE STATES
  const queryClient = useQueryClient();
  const [value, setValue] = useState();
  const [loadingState, setLoadingState] = useState({
    initial: false,
    previous: false,
  });
  const [filter, setFilter] = useState({
    currentPage: 1,
  });
  const [hasMore, setHasMore] = useState(false);
  const [messages, setMessages] = useState([]);
  const [extraPaddingBottom, setExtraPaddingBottom] = useState(0);

  // DEFINE REFS
  const refScrollableWrapper = useRef();
  const refComposeMessage = useRef();

  // DEFINE VALUES
  const latestMessageId = useMemo(
    () => (messages.length > 0 ? messages[messages.length - 1].id : null),
    [messages],
  );

  const sellerInformation = useMemo(() => {
    const object = {
      name: '',
    };

    if (activeRoomData) {
      const { sellerCentralStore, sellerBranchStore } = activeRoomData;

      object.name = sellerBranchStore
        ? sellerBranchStore?.branchCompanyName
        : sellerCentralStore?.companyName;
    }

    return object;
  }, [activeRoomData]);

  const header = useMemo(() => {
    if (activeRoomData) {
      const { customRoomAvatar, customRoomName } = activeRoomData;

      return (
        <div className="flex h-full gap-2 p-3 items-center">
          <div
            className="flex gap-2 items-center"
            role="button"
            tabIndex={0}
            onClick={() => {
              setActiveRoomId(null);
              setActiveRoomData(null);
            }}
          >
            <div className="h-[40px] w-[40px] rounded-xl overflow-hidden bg-gray-300 border">
              <img
                src={customRoomAvatar}
                alt=""
                className="w-full h-full object-center object-cover"
              />
            </div>
          </div>
          <div>{customRoomName ?? sellerInformation.name}</div>
        </div>
      );
    }

    return <></>;
  }, [activeRoomData, sellerInformation.name, setActiveRoomData, setActiveRoomId]);

  const suggestions = useMemo(() => {
    const suggestionMessages = [];

    if (typeof value === 'object' && value !== null) {
      if (value?.type === 'product_retail') {
        suggestionMessages.push('Halo, item ini ready?', 'Bisa dikirim hari ini?');
      }
      if (value?.type === 'po') {
        suggestionMessages.push('Halo, bisa dikirim hari ini?');
      }
    }

    suggestionMessages.push('Halo', 'Terima kasih');

    return suggestionMessages;
  }, [value]);

  // DEFINE FUNCTIONS
  const scrollToLatest = () => {
    const scrollElement = refScrollableWrapper.current.getScrollElement();
    scrollElement.scrollTop = scrollElement.scrollHeight;
  };

  const refreshRoomData = useCallback(
    async (payload) => {
      try {
        const responseData = await getDetailRoomApi({
          id: payload.id,
          code: payload.code,
        });
        setActiveRoomData(responseData);
      } catch (error) {
        console.error(error);
      }
    },
    [setActiveRoomData],
  );

  const fetchFirstPageMessages = useCallback(async () => {
    try {
      setLoadingState((state) => ({ ...state, initial: true }));
      const data = await fetchRoomHistoryApi(activeRoomId, { page: 1, size: PAGE_SIZE_HISTORY });
      const parsedMessages = data.content || [];

      setMessages((parsedMessages || []).reverse());
      scrollToLatest();
      if (parsedMessages.length >= PAGE_SIZE_HISTORY) {
        setHasMore(true);
      }
    } catch (error) {
      console.error('fetchMessages error', error);
    } finally {
      setLoadingState((state) => ({ ...state, initial: false }));
    }
  }, [activeRoomId]);

  const abortControllerRef = useRef(new AbortController());

  const loadLatestMessagesDependsOnLatestMessageId = useCallback(
    async (latestId, isListen = false) => {
      try {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();

        const responseData = await fetchRoomHistoryApi(
          activeRoomId,
          {
            page: 1,
            size: PAGE_SIZE_HISTORY,
          },
          abortControllerRef.current.signal,
        );

        const latestMessages = responseData?.content || [];
        const indexOfLatestMessage = latestMessages.findIndex((item) => item.id === latestId);

        if (indexOfLatestMessage === 0) {
          return;
        }

        if (indexOfLatestMessage >= 0 && indexOfLatestMessage <= PAGE_SIZE_HISTORY - 1) {
          latestMessages.splice(indexOfLatestMessage, latestMessages.length - indexOfLatestMessage);

          setMessages((state) => [...state, ...latestMessages.reverse()]);
          scrollToLatest();
        } else {
          fetchFirstPageMessages();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [activeRoomId, fetchFirstPageMessages],
  );

  const fetchPreviousMessages = useCallback(
    async (page, latestPreviousChat = null) => {
      try {
        setLoadingState((state) => ({ ...state, previous: true }));
        const data = await fetchRoomHistoryApi(activeRoomId, {
          page,
          size: PAGE_SIZE_HISTORY,
        });

        const parsedMessage = data?.content || [];

        setMessages((state) => [...parsedMessage.reverse(), ...state]);

        if (parsedMessage.length >= 0) {
          setFilter((state) => ({ ...state, currentPage: state.currentPage + 1 }));
        }
        const scrollElement = refScrollableWrapper.current.getScrollElement();
        scrollElement.querySelector(`#chat-${latestPreviousChat.id}`).scrollIntoView();
        setHasMore(parsedMessage.length >= PAGE_SIZE_HISTORY);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingState((state) => ({ ...state, previous: false }));
      }
    },
    [activeRoomId],
  );

  const loadPreviousMessages = useCallback(
    throttle(() => {
      const [first] = messages;
      fetchPreviousMessages(filter.currentPage + 1, first);
    }, 1000),
    [filter.currentPage, messages],
  );

  const handleChange = useCallback((newMessageValue) => {
    setValue(newMessageValue);
  }, []);

  const mutationSendMessage = useMutation(({ id_room, chat }) =>
    sendMessageToRoomApi({ id_room, chat }),
  );

  const handleSend = useCallback(() => {
    let valueOfChat;

    if (value === null) {
      valueOfChat = '';
    }

    if (typeof value === 'string') {
      valueOfChat = value;
    }

    if (typeof value === 'object' && value !== null) {
      valueOfChat = JSON.stringify(value);
    }

    mutationSendMessage.mutate(
      { id_room: activeRoomId, chat: valueOfChat },
      {
        onSuccess: async () => {
          setValue(null);
          loadLatestMessagesDependsOnLatestMessageId(latestMessageId);
        },
      },
    );
  }, [
    activeRoomId,
    latestMessageId,
    loadLatestMessagesDependsOnLatestMessageId,
    mutationSendMessage,
    value,
  ]);

  const handleScroll = useCallback(
    (event) => {
      const { target } = event;
      const { scrollTop } = target;
      if (scrollTop <= 0 && hasMore) {
        loadPreviousMessages();
      }
    },
    [loadPreviousMessages, hasMore],
  );

  // SIDE EFFECT

  // initial page load
  useEffect(() => {
    fetchFirstPageMessages();

    return () => {
      setInitialComposeMessage(null);
    };
  }, []);

  // set compose message
  useEffect(() => {
    setValue(initialComposeMessage);
  }, [initialComposeMessage]);

  // Cron metadata of room
  useEffect(() => {
    let interval = null;
    interval = setInterval(
      () => refreshRoomData({ id: activeRoomData.id, code: activeRoomData.code }),
      REFRESH_INTERVAL_ACTIVE_ROOM,
    );

    return () => {
      clearInterval(interval);
    };
  }, [activeRoomData]);

  useEffect(() => {
    let interval = null;

    interval = setInterval(
      () => loadLatestMessagesDependsOnLatestMessageId(latestMessageId, true),
      REFRESH_INTERVAL_ACTIVE_ROOM,
    );
    return () => {
      clearInterval(interval);
    };
  }, [latestMessageId]);

  // update padding bottom of messages container
  useEffect(() => {
    if (!refComposeMessage.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const { clientHeight } = refComposeMessage?.current || {};
      setExtraPaddingBottom(clientHeight ?? 0);
    });
    resizeObserver.observe(refComposeMessage.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div
      className="max-w-screen-sm mx-auto h-full relative pt-[60px]"
      style={{ paddingBottom: extraPaddingBottom }}
    >
      <div className="absolute top-0 left-0 right-0 bg-white h-[60px] border-b">{header}</div>
      <SimpleBar
        className="h-full py-3 pl-3 pr-4 bg-grayScale01 bottom-0 top-0"
        ref={refScrollableWrapper}
        onScrollCapture={handleScroll}
      >
        <div className="flex flex-col gap-3">
          {messages.length > 0 && loadingState.previous && (
            <div className="flex justify-center items-center">
              <div className="flex items-center gap-2 rounded-lg text-xs">
                <Loader size={20} />
                <span>Memuat pesan...</span>
              </div>
            </div>
          )}

          {messages.length <= 0 && !!loadingState.initial && (
            <div className="pt-[60px] flex items-center justify-center">
              <div className="flex flex-col items-center gap-3">
                <Loader />
                <div>Memuat Pesan</div>
              </div>
            </div>
          )}
          {messages.map((item, index) => {
            const messageDate = moment(item.entryDate || '').format('DD MMMM YYYY');

            // show date info nudge above the first message of each date
            if (!lastMessageDate.current || lastMessageDate.current !== messageDate) {
              lastMessageDate.current = messageDate;

              return (
                <>
                  <div className="w-fit self-center bg-theme-primary-main my-4 px-3 py-1 rounded-xl">
                    <p className="font-light text-xs text-white">{messageDate}</p>
                  </div>
                  <div id={`chat-${item.id}`} key={item.id || index}>
                    <MessageItemWrapper message={item} activeRoomData={activeRoomData} />
                  </div>
                </>
              );
            }

            return (
              <div id={`chat-${item.id}`} key={item.id || index}>
                <MessageItemWrapper message={item} activeRoomData={activeRoomData} />
              </div>
            );
          })}
        </div>
      </SimpleBar>
      <div className="absolute bottom-0 w-full bg-white border-t" ref={refComposeMessage}>
        <div className="bg-theme-primary-main bg-opacity-10">
          <Chat.ComposeMessage
            suggestion={suggestions}
            isLoadingSend={
              (mutationSendMessage.isLoading && !mutationSendMessage.isError) ||
              loadingState.initial
            }
            value={value}
            onClickSend={handleSend}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveRoom;
